<template>
	<v-list-item>
		<v-list-item-content>
			<v-list-item-title>
				{{ title }}
			</v-list-item-title>
			<v-list-item-subtitle v-if="subtitle">
				{{ subtitle }}
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-action class="flex-row align-center">
			{{ count }}
			<v-btn icon :disabled="count===0" @click="$emit('download')">
				<v-icon>mdi-download</v-icon>
			</v-btn>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
export default {
	props: {
		title: String,
		subtitle: String,
		count: Number,
	},
}
</script>