<template>
	<v-container>
		<v-expansion-panels v-model="panel">
			<v-expansion-panel @change="loadMesechen">
				<v-expansion-panel-header>Месечен отчет</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div v-if="!dataMesechen" class="text-center">
						<v-progress-circular indeterminate color="grey lighten-1" />
					</div>
					<div v-if="dataMesechen" style="max-width:800px">
						<v-list dense outlined>
							<radio-transfer-list-item
								title="Чакащи радио отчети"
								subtitle="Показания, импортирани в системата, но все още не прехвърлени към уред"
								:count="dataMesechen.readings"
								@download="download('getReadings', true)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Радио отчети без съвпадение"
								subtitle="Показания, импортирани в системата, които нямат съвпадение с уред"
								:count="dataMesechen.readingsNoMatch"
								@download="download('getReadingsNoMatch')"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Уреди с показание"
								subtitle="От всички радио уреди за сесията, колко вече имат показание"
								:count="dataMesechen.devWithValue"
								@download="download('getWithVal', true)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Уреди без показание"
								subtitle="Радио уреди без показание и без импортирана стойност"
								:count="dataMesechen.devNoValue"
								@download="download('getMissing', true)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Показания с грешка"
								subtitle="Показания, за които полето 'status' е различно от 0"
								:count="dataMesechen.devWithError"
								@download="download('getWithError', true)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Съвпадения с отчет"
								subtitle="Уреди без показание, за които има импортирана стойност, готова за прехвърляне"
								:count="dataMesechen.devMatch"
								@download="download('getPending', true)"
							/>
						</v-list>
						<div class="text-right mt-4">
							<v-btn
								text color="primary"
								:disabled="!dataMesechen.readings"
								:loading="transferringMesechen"
								@click="onTransferMesechen"
							>
								<v-icon left>mdi-transfer-right</v-icon>
								Трансфер
							</v-btn>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel @change="loadGodishen">
				<v-expansion-panel-header>Годишен отчет</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div v-if="!dataGodishen" class="text-center">
						<v-progress-circular indeterminate color="grey lighten-1" />
					</div>
					<div v-if="dataGodishen" style="max-width:800px">
						<v-list dense outlined>
							<radio-transfer-list-item
								title="Чакащи радио отчети"
								subtitle="Показания, импортирани в системата, но все още не прехвърлени към уред"
								:count="dataGodishen.readings"
								@download="download('getReadings', false)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Радио отчети без съвпадение"
								subtitle="Показания, импортирани в системата, които нямат съвпадение с уред"
								:count="dataGodishen.readingsNoMatch"
								@download="download('getReadingsNoMatch')"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Уреди с показание"
								subtitle="От всички радио уреди за сесията, колко вече имат показание"
								:count="dataGodishen.devWithValue"
								@download="download('getWithVal', false)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Уреди без показание"
								subtitle="Радио уреди без показание и без импортирана стойност"
								:count="dataGodishen.devNoValue"
								@download="download('getMissing', false)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Показания с грешка"
								subtitle="Показания, за които полето 'status' е различно от 0"
								:count="dataGodishen.devWithError"
								@download="download('getWithError', false)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Съвпадения с отчет"
								subtitle="Уреди без показание, за които има импортирана стойност, готова за прехвърляне"
								:count="dataGodishen.devMatch"
								@download="download('getPending', false)"
							/>
						</v-list>
						<div class="text-right mt-4">
							<v-btn
								text color="primary"
								:disabled="!dataGodishen.readings"
								:loading="transferringGodishen"
								@click="onTransferGodishen"
							>
								<v-icon left>mdi-transfer-right</v-icon>
								Трансфер
							</v-btn>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RadioTransferListItem from "./radio-transfer-list-item";
import RadioOtchetiModel from "./RadioOtcheti";

const radioOtchetiModel = new RadioOtchetiModel();

export default {
	components: {RadioTransferListItem},
	mixins: [SelectedUtilityMixin],
	props: ['session'],
	data(){
		return {
			panel: null,
			dataMesechen: null,
			dataGodishen: null,
			loadingMesechen: false,
			loadingGodishen: false,
			transferringMesechen: false,
			transferringGodishen: false,
		}
	},
	watch: {
		selectedUtility(){
			this.reset();
		},
		session(){
			this.reset();
		},
	},
	methods: {
		reset(){
			this.panel = null;
			this.dataMesechen = null;
			this.dataGodishen = null;
			this.loadingMesechen = false;
			this.loadingGodishen = false;
			this.transferringMesechen = false;
			this.transferringGodishen = false;
		},
		async onTransferMesechen(){
			if (this.transferringMesechen) return;
			this.transferringMesechen = true;
			try {
				this.dataMesechen = await radioOtchetiModel.transferReadings(this.selectedUtility, this.session, true);
				this.dataGodishen = null;
			}
			finally {
				this.transferringMesechen = false;
			}
		},
		async onTransferGodishen(){
			if (this.transferringGodishen) return;
			this.transferringGodishen = true;
			try {
				this.dataMesechen = null;
				this.dataGodishen = await radioOtchetiModel.transferReadings(this.selectedUtility, this.session, false);
			}
			finally {
				this.transferringGodishen = false;
			}
		},
		async loadMesechen() {
			if (this.dataMesechen) return;
			if (this.loadingMesechen) return;
			this.loadingMesechen = true;
			try {
				this.dataMesechen = await radioOtchetiModel.transferStatus(this.selectedUtility, this.session, true);
			}
			finally {
				this.loadingMesechen = false;
			}
		},
		async loadGodishen() {
			if (this.dataGodishen) return;
			if (this.loadingGodishen) return;
			this.loadingGodishen = true;
			try {
				this.dataGodishen = await radioOtchetiModel.transferStatus(this.selectedUtility, this.session, false);
			}
			finally {
				this.loadingGodishen = false;
			}
		},
		download(method, mesechen) {
			switch (method) {
				case 'getReadings':
				case 'getReadingsNoMatch':
				case 'getWithVal':
				case 'getMissing':
				case 'getPending':
				case 'getWithError':
					break;
				default:
					return;
			}
			window.location.href = radioOtchetiModel.getRequestURL(method, {
				utility: this.selectedUtility,
				session: this.session,
				mesechen: !!mesechen,
			});
		}
	},
}
</script>