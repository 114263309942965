<!--
Форма за масов ъпдейт на служебни показания, на база на предна стойност с корекция
-->
<template>
	<v-simple-table>
		<thead>
		<tr>
			<th>ТИП</th>
			<th>СЕСИЯ</th>
			<th width="1%">КОРЕКЦИЯ</th>
		</tr>
		</thead>
		<tbody>
		<tr v-for="(label,type) of rowsMap" :key="type">
			<td>{{label}}</td>
			<td>
				<session-select
					dense outlined clearable
					:utility="selectedUtility"
					:station="station"
					v-model="data[type].session"
					:readonly="readonly"
					:disabled="readonly"
				/>
			</td>
			<td>
				<v-text-field
					dense outlined hide-details="auto" suffix="%"
					style="width: 150px"
					v-model="data[type].correction"
					:readonly="readonly"
					:disabled="readonly"
				/>
			</td>
		</tr>
		</tbody>
	</v-simple-table>
</template>

<script>
import SessionSelect from "@/views/sessions/SessionSelect";
import {cloneDeep} from "lodash";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";

const typeMap = {
	radiator: "Радиатори",
	vodomer: "Водомери",
	toplomer: "Топломери",
};

const dataInit = Object.fromEntries(
	Object.keys(typeMap)
		.map(k => [k, {session: null, correction: null}])
);

export default {
	components: {SessionSelect},
	mixins: [SelectedUtilityMixin],
	props: [
		'station',
		'value',
		'readonly',
	],
	data(){
		return {
			data: cloneDeep(dataInit),
			rowsMap: cloneDeep(typeMap),
		}
	},
	watch: {
		data: {
			immediate: true,
			handler(){
				this.$emit('update:value', this.data);
			},
		},
		value: {
			immediate: true,
			handler(val){
				this.data = val || cloneDeep(dataInit);
			},
		},
	},
	methods: {
		reset(){
			this.data = cloneDeep(dataInit);
		},
	},
}
</script>