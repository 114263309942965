import {ITTIModelBase} from "@/ittijs/ITTIModel";

export default class RadioOtcheti extends ITTIModelBase {
	constructor() {
		super('SelfReport');
	}
	getMissingCount(utility: string, session: number, summer: boolean){
		return this.fetch('getMissingCount', {utility, session, summer});
	}
	transferStatus(utility: string, session: number, mesechen: boolean, summer: boolean){
		return this.fetch('transferStatus', {utility, session, mesechen, summer});
	}
	transferReadings(utility: string, session: number, mesechen: boolean, summer: boolean){
		return this.fetch('transferReadings', {utility, session, mesechen, summer}, {});
	}
	downloadAll(utility: string, session: number, summer: boolean){
		return this.fetch('downloadAll', {utility, session, summer});
	}
}
