<template>
	<div>
		<v-list-item :class="className" class="lighten-4">
			<v-list-item-content>
				<v-list-item-title class="text-h6">{{label}}</v-list-item-title>
			</v-list-item-content>
			<v-btn icon @click="setAll">
				<v-icon>mdi-checkbox-marked-outline</v-icon>
			</v-btn>
			<v-btn icon @click="setNone">
				<v-icon>mdi-checkbox-blank-outline</v-icon>
			</v-btn>
		</v-list-item>
		<v-pagination total-visible="10" v-model="page" :length="totalPages" v-if="totalPages > 1" />
		<v-list-item-group :value="value" @change="change" multiple>
			<v-list-item v-for="num in itemsCurrentPage" :key="num" :value="num">
				<template v-slot:default="{ active }">
					<v-list-item-action>
						<v-checkbox :input-value="active"></v-checkbox>
					</v-list-item-action>
					<v-list-item-content>
						<slot name="item" v-bind:number="num">{{num}}</slot>
					</v-list-item-content>
					<v-list-item-icon v-if="$scopedSlots.icon">
						<slot name="icon" v-bind:number="num" />
					</v-list-item-icon>
				</template>
			</v-list-item>
		</v-list-item-group>
	</div>
</template>

<script>
export default {
	props: [
		'label',
		'className',
		'items',
		'value',
	],
	data(){
		return {
			page: 1,
			ipp: 10,
		}
	},
	computed: {
		totalPages(){
			return Math.ceil(this.items.length / this.ipp);
		},
		itemsCurrentPage(){
			const start = (this.page - 1) * this.ipp;
			const end = start + this.ipp;
			return this.items.slice(start, end);
		},
	},
	methods: {
		change($e){
			// $e contains leftovers from other pages - clean up first
			$e = $e.filter(val => this.itemsCurrentPage.indexOf(val) >= 0);
			// keep track of added items in the current page
			let append = [...$e];

			const newVal = [...this.value].filter(val => {
				// if not in itemsCurrentPage, then keep as is
				if (this.itemsCurrentPage.indexOf(val) === -1) return true;
				if ($e.indexOf(val) >= 0) {
					append = append.filter(v => v!==val);
					return true;
				}
				else {
					return false;
				}
			});
			this.$emit('input', [...newVal, ...append]);
		},
		setNone(){
			this.$emit('input', []);
		},
		setAll(){
			this.$emit('input', [...this.items]);
		},
	},
}
</script>