import {ITTIModelBase} from "@/ittijs/ITTIModel";

export default class RadioOtcheti extends ITTIModelBase {
	constructor() {
		super('RadioOtcheti');
	}
	transferStatus(utility: string, session: number, mesechen: boolean){
		return this.fetch('transferStatus', {utility, session, mesechen});
	}
	transferReadings(utility: string, session: number, mesechen: boolean){
		return this.fetch('transferReadings', {utility, session, mesechen}, {});
	}
	deleteReadings(utility: string, session: number){
		return this.fetch('deleteReadings', {utility, session}, {});
	}
}
