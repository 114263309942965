<template>
	<v-container>
		<data-loader
			ref="statusLoader"
			:watch="{selectedUtility, session}"
			:callback="()=>getStatus()"
			:data.sync="statusData"
		>
			<template #loading>
				<v-progress-linear indeterminate rounded />
			</template>
			<template #default="{data, reload}">
				<div>
					<v-alert type="info" text outlined v-if="data">
						Общо станции: {{data.countTotal}}, от тях с генерирани сметки: {{data.countReady}}
					</v-alert>
					<progress-tracker v-if="trackerUUID" :uuid="trackerUUID" @ready="startedTracker || reload()">
						<template #default="{progress, current, total, time_remaining, result, error, log}">
							<div>
								<v-alert v-if="error" type="error" dense class="mt-5">
									{{error}}
								</v-alert>
								<v-progress-linear
									v-else-if="progress < 1"
									rounded striped height="24" :value="progress * 100 || 0">
									{{ (progress * 100 || 0).toFixed(1) }}%
									({{current}}/{{total}} станции, остава {{time_remaining}})
								</v-progress-linear>
								<v-alert v-else-if="result && !result.error" type="success" dense class="mt-5">
									Готово!
								</v-alert>
								<div class="text-pre-line mx-2" v-if="log">{{log}}</div>
							</div>
						</template>
					</progress-tracker>
					<v-btn v-if="!data?.running" @click="doStart(false)" class="mt-2">Генерирай</v-btn>
					<confirm-dialog v-if="!data?.running" @confirm="doStart(true)" color-confirm="error">
						<template #activator="{attrs,on}">
							<v-btn v-bind="attrs" v-on="on" class="ms-2 mt-2 error">Генерирай наново</v-btn>
						</template>
						<div class="text-body-1">Това ще презапише вече генерираните сметки!</div>
					</confirm-dialog>
				</div>
			</template>
		</data-loader>
	</v-container>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import ProgressTracker from "@/ittijs/components/ProgressTracker.vue";
import ConfirmDialog from "@/ittijs/ConfirmDialog.vue";

export default {
	components : {ConfirmDialog, ProgressTracker, DataLoader},
	mixins: [
		SelectedUtilityMixin,
		IModelMixin,
	],
	props: ['session'],
	data(){
		return {
			startedTracker: null,
			statusData: null,
		}
	},
	watch: {
		selectedUtility(){
			this.startedTracker = null;
		},
		session(){
			this.startedTracker = null;
		},
	},
	computed: {
		trackerUUID(){
			return this.startedTracker || this.statusData?.tracker;
		},
	},
	methods: {
		getStatus() {
			return this.imodel.getIzrPDFsStatus(this.selectedUtility, this.session);
		},
		async doStart(force) {
			this.startedTracker = await this.imodel.makeIzrPDFs(this.selectedUtility, this.session, force);
			this.$refs.statusLoader.loadDebounce();
		},
	},
}
</script>