<template>
	<v-container>
		Преизчисляване на консумациите за всички станции на годишен отчет
		<br><br>
		<div class="text-left">
			<btn-loading-promise
				:click="()=>{
					result = null;
					return imodel.recalcConsumption(selectedUtility, session);
				}"
				@ready="result = $event"
			>
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading">
						Преизчисли
					</v-btn>
				</template>
			</btn-loading-promise>
		</div>
		<div v-if="result" class="mt-3">
			<v-expansion-panels>
				<v-expansion-panel v-if="result.success && result.success.length">

					<v-expansion-panel-header>
						Успешно
						<template #actions>
							<v-chip small color="success">{{result.success.length}}</v-chip>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-divider/>
						<div class="text-right my-2">
							<export-csv small color="secondary" filename="consumption-success.csv"
								:headers="mkHeaders([
									'station_N',
									'work_days_heat',
									'work_days_hw',
									'toplomer_obsht_value',
									'corekcionen_coeficient',
								])"
								:items="result.success.map(item => item.station)"
							>
								<v-icon left>mdi-export</v-icon>
								CSV
							</export-csv>
						</div>
						<v-simple-table dense fixed-header height="40vh">
							<thead>
							<tr>
								<th><field-label :imodel="sdmodel" name="station_N" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="work_days_heat" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="work_days_hw" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="toplomer_obsht_value" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="corekcionen_coeficient" /></th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(item, i) in result.success" :key="i">
								<td><field-value :imodel="sdmodel" name="station_N" :value="item.station.station_N" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="work_days_heat" :value="item.station.work_days_heat" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="work_days_hw" :value="item.station.work_days_hw" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="toplomer_obsht_value" :value="item.station.toplomer_obsht_value" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="corekcionen_coeficient" :value="item.station.corekcionen_coeficient" /></td>
							</tr>
							</tbody>
						</v-simple-table>
					</v-expansion-panel-content>
				</v-expansion-panel>

				<v-expansion-panel v-if="result.error && result.error.length">
					<v-expansion-panel-header>
						Грешки
						<template #actions>
							<v-chip small color="error">{{result.error.length}}</v-chip>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-divider/>
						<div class="text-right my-2">
							<export-csv small color="secondary" filename="consumption-error.csv"
								:headers="mkHeaders([
									'station_N',
									'work_days_heat',
									'work_days_hw',
									'toplomer_obsht_value',
									'corekcionen_coeficient',
									{
										text: 'Грешка',
										value: 'error',
									}
								])"
								:items="result.error.map(item => {
									const res = item.station;
									res.error = item.error;
									return res;
								})"
							>
								<v-icon left>mdi-export</v-icon>
								CSV
							</export-csv>
						</div>
						<v-simple-table dense fixed-header height="40vh">
							<thead>
							<tr>
								<th><field-label :imodel="sdmodel" name="station_N" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="work_days_heat" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="work_days_hw" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="toplomer_obsht_value" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="corekcionen_coeficient" /></th>
								<th class="error--text">Грешка</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(item, i) in result.error" :key="i">
								<td><field-value :imodel="sdmodel" name="station_N" :value="item.station.station_N" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="work_days_heat" :value="item.station.work_days_heat" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="work_days_hw" :value="item.station.work_days_hw" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="toplomer_obsht_value" :value="item.station.toplomer_obsht_value" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="corekcionen_coeficient" :value="item.station.corekcionen_coeficient" /></td>
								<td class="error--text">{{item.error}}</td>
							</tr>
							</tbody>
						</v-simple-table>
					</v-expansion-panel-content>
				</v-expansion-panel>

			</v-expansion-panels>
		</div>
	</v-container>
</template>

<script>
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import IModelMixin from "@/ittijs/IModelMixin";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import StationDataModel from "@/views/station_data/Model"
import FieldLabel from "@/ittijs/components/FieldLabel";
import FieldValue from "@/ittijs/components/FieldValue";
import ExportCsv from "@/ittijs/components/export-csv";

const sdmodel = new StationDataModel;

export default {
	components: {ExportCsv, FieldValue, FieldLabel, BtnLoadingPromise},
	mixins: [IModelMixin, SelectedUtilityMixin],
	props: ['session'],
	data(){
		return {
			result: null,
			sdmodel,
		}
	},
	methods: {
		mkHeaders(names){
			// convert list of field names to headers supported by export-csv (and v-data-table)
			return names.map(name => {
				if (typeof name === 'string') {
					return {
						csv: true,
						text: this.sdmodel.getLabel(name),
						value: name,
					}
				}
				else if (typeof name === "object") {
					name.csv = true;
					return name;
				}
				return name;
			});
		},
	},
}
</script>