<template>
	<v-container>
		<v-alert type="info" v-if="summer" dense>
			Отоплителните уреди ще бъдат игнорирани
		</v-alert>
		<v-expansion-panels v-model="panel">
			<v-expansion-panel @change="loadMissing">
				<v-expansion-panel-header>Без съвпадение</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div v-if="dataMissing===null" class="text-center">
						<v-progress-circular indeterminate color="grey lighten-1" />
					</div>
					<div v-if="dataMissing!==null" style="max-width:800px">
						<v-list dense outlined>
							<radio-transfer-list-item
								title="Няма съвпадение с уред"
								subtitle="Самоотчети, които нямат съвпадение по номер на уред/имот"
								:count="dataMissing"
								@download="downloadMissing"
							/>
						</v-list>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel @change="loadMesechen">
				<v-expansion-panel-header>Месечен отчет</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div v-if="!dataMesechen" class="text-center">
						<v-progress-circular indeterminate color="grey lighten-1" />
					</div>
					<div v-if="dataMesechen" style="max-width:800px">
						<v-list dense outlined>
							<radio-transfer-list-item
								title="Уреди със самоотчет"
								subtitle="Общ брой уреди в сесията с въведен самоотчет"
								:count="dataMesechen.devWithValue"
								@download="download('getWithVal', true)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Конфликтни самоотчети"
								subtitle="Уреди с друг тип отчет, за които има импортиран самоотчет"
								:count="dataMesechen.devConflict"
								@download="download('getConflict', true)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Готови за трансфер"
								subtitle="Брой импортирани самоотчети, които са готови за трансфер към уред"
								:count="dataMesechen.devMatch"
								@download="download('getPending', true)"
							/>
						</v-list>
						<div class="text-right mt-4">
							<v-btn
								text color="primary"
								:disabled="!dataMesechen.devMatch"
								:loading="transferringMesechen"
								@click="onTransferMesechen"
							>
								<v-icon left>mdi-transfer-right</v-icon>
								Трансфер
							</v-btn>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel @change="loadGodishen">
				<v-expansion-panel-header>Годишен отчет</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div v-if="!dataGodishen" class="text-center">
						<v-progress-circular indeterminate color="grey lighten-1" />
					</div>
					<div v-if="dataGodishen" style="max-width:800px">
						<v-list dense outlined>
							<radio-transfer-list-item
								title="Уреди със самоотчет"
								subtitle="Общ брой уреди в сесията с въведен самоотчет"
								:count="dataGodishen.devWithValue"
								@download="download('getWithVal', false)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Конфликтни самоотчети"
								subtitle="Уреди с друг тип отчет, за които има импортиран самоотчет"
								:count="dataGodishen.devConflict"
								@download="download('getConflict', false)"
							/>
							<v-divider/>
							<radio-transfer-list-item
								title="Готови за трансфер"
								subtitle="Брой импортирани самоотчети, които са готови за трансфер към уред"
								:count="dataGodishen.devMatch"
								@download="download('getPending', false)"
							/>
						</v-list>
						<div class="text-right mt-4">
							<v-btn
								text color="primary"
								:disabled="!dataGodishen.devMatch"
								:loading="transferringGodishen"
								@click="onTransferGodishen"
							>
								<v-icon left>mdi-transfer-right</v-icon>
								Трансфер
							</v-btn>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RadioTransferListItem from "@/views/sessions/Current/radio-transfer-list-item";
import SelfReportModel from "./SelfReport";

const selfReportModel = new SelfReportModel();

export default {
	components: {RadioTransferListItem},
	mixins: [SelectedUtilityMixin],
	props: {
		session: {},
		summer: Boolean,
	},
	data(){
		return {
			panel: null,
			dataMissing: null,
			dataMesechen: null,
			dataGodishen: null,
			loadingMissing: false,
			loadingMesechen: false,
			loadingGodishen: false,
			transferringMesechen: false,
			transferringGodishen: false,
		}
	},
	watch: {
		selectedUtility(){
			this.reset();
		},
		session(){
			this.reset();
		},
	},
	methods: {
		reset(){
			this.panel = null;
			this.dataMissing = null;
			this.dataMesechen = null;
			this.dataGodishen = null;
			this.loadingMissing = false;
			this.loadingMesechen = false;
			this.loadingGodishen = false;
			this.transferringMesechen = false;
			this.transferringGodishen = false;
		},
		async onTransferMesechen(){
			if (this.transferringMesechen) return;
			this.transferringMesechen = true;
			try {
				this.dataMesechen = await selfReportModel.transferReadings(this.selectedUtility, this.session, true, this.summer);
			}
			finally {
				this.transferringMesechen = false;
			}
		},
		async onTransferGodishen(){
			if (this.transferringGodishen) return;
			this.transferringGodishen = true;
			try {
				this.dataGodishen = await selfReportModel.transferReadings(this.selectedUtility, this.session, false, this.summer);
			}
			finally {
				this.transferringGodishen = false;
			}
		},
		async loadMissing() {
			if (this.dataMissing) return;
			if (this.loadingMissing) return;
			this.loadingMissing = true;
			try {
				this.dataMissing = await selfReportModel.getMissingCount(this.selectedUtility, this.session, this.summer);
			}
			finally {
				this.loadingMissing = false;
			}
		},
		async loadMesechen() {
			if (this.dataMesechen) return;
			if (this.loadingMesechen) return;
			this.loadingMesechen = true;
			try {
				this.dataMesechen = await selfReportModel.transferStatus(this.selectedUtility, this.session, true, this.summer);
			}
			finally {
				this.loadingMesechen = false;
			}
		},
		async loadGodishen() {
			if (this.dataGodishen) return;
			if (this.loadingGodishen) return;
			this.loadingGodishen = true;
			try {
				this.dataGodishen = await selfReportModel.transferStatus(this.selectedUtility, this.session, false, this.summer);
			}
			finally {
				this.loadingGodishen = false;
			}
		},
		downloadMissing() {
			window.location.href = selfReportModel.getRequestURL('getMissing', {
				utility: this.selectedUtility,
				session: this.session,
				summer: this.summer,
			});
		},
		download(method, mesechen) {
			switch (method) {
				case 'getWithVal':
				case 'getConflict':
				case 'getPending':
					break;
				default:
					return;
			}
			window.location.href = selfReportModel.getRequestURL(method, {
				utility: this.selectedUtility,
				session: this.session,
				mesechen: !!mesechen,
				summer: this.summer,
			});
		},
	},
}
</script>