<!--
Версия на items-config, която е за UPDATE и ползва data table вместо list group.
Трябва да има същия IO интерфейс
-->

<template>
	<div>
		<v-list-item :class="className" class="lighten-4">
			<v-list-item-content>
				<v-list-item-title class="text-h6">{{label}}</v-list-item-title>
			</v-list-item-content>
			<v-btn icon @click="setAll">
				<v-icon>mdi-checkbox-marked-outline</v-icon>
			</v-btn>
			<v-btn icon @click="setNone">
				<v-icon>mdi-checkbox-blank-outline</v-icon>
			</v-btn>
		</v-list-item>

		<v-data-table
			dense
			:headers="headers"
			:items="itemsTable"
			show-select
			v-model="selection"
			item-key="_key"
		>
			<template #header.data-table-select="{}">
			</template>
			<template #item._item="{value}">
				<slot name="item" v-bind:number="value">{{value}}</slot>
			</template>
			<template v-for="f in diffFieldsSlots" v-slot:[f]="{value}">
				<div :key="f" v-if="value">
					<div class="red--text text-decoration-line-through">{{ value[0] }}</div>
					<div class="green--text">{{ value[1] }}</div>
				</div>
			</template>
		</v-data-table>

	</div>
</template>

<script>
export default {
	props: [
		'label',
		'className',
		'items',
		'value', // в този компонент това работи само в едната посока - отвътре навън
		'diff',
	],
	data(){
		return {
			selection: [],
		}
	},
	watch: {
		selection(val){
			this.$emit('input', val.map(item=>item._key));
		},
	},
	computed: {
		diffFields(){
			const result = {};
			for (const num in this.diff) {
				for (const f in this.diff[num]) {
					if (!result[f]) result[f] = true;
				}
			}
			return Object.keys(result);
		},
		diffFieldsSlots(){
			return this.diffFields.map(f => "item." + f);
		},
		headers(){
			return [
				{
					text: '_item',
					value: '_item',
				},
				...this.diffFields.map(f => ({
					text: f,
					value: f,
				})),
			];
		},
		itemsTable(){
			return this.items.map(num => {
				return {
					"_key": num,
					"_item": num,
					...this.diff[num],
				}
			});
		},
	},
	methods: {
		setNone(){
			this.selection = [];
		},
		setAll(){
			this.selection = this.itemsTable;
		},
	},
}
</script>