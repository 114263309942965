import { render, staticRenderFns } from "./radio-import.vue?vue&type=template&id=5c2fb1ba&scoped=true"
import script from "./radio-import.vue?vue&type=script&lang=js"
export * from "./radio-import.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2fb1ba",
  null
  
)

export default component.exports