<template>
	<div>

		<div class="d-flex pa-2">
			<v-sheet elevation="2" rounded max-width="300" class="ma-2">
				<v-toolbar color="headings" flat dense class="rounded-t">Модели у-ва</v-toolbar>
				<base-input type="checkboxgroup" :options="deviceModelsList" v-model="deviceModels" class="mx-2" />
				<v-divider/>
				<v-btn icon @click="checkAllModel"><v-icon>mdi-checkbox-multiple-marked-outline</v-icon></v-btn>
				<v-btn icon @click="uncheckAllModel"><v-icon>mdi-checkbox-multiple-blank-outline</v-icon></v-btn>
			</v-sheet>
			<v-sheet elevation="2" rounded max-width="300" class="pb-2 ma-2">
				<v-toolbar color="headings" flat dense class="rounded-t">Тип район</v-toolbar>
				<base-input type="radiogroup" v-model="areaGroup" :options="[
					// todo - get from php, don't hardcode
					{value:'monthly',text:'Мес. отчет'},
					{value:'yearly', text:'Год. отчет'},
					{value:'montaji',text:'Монтажи'},
					]" class="mx-2" />
			</v-sheet>
			<v-sheet elevation="2" rounded max-width="300" class="pb-2 ma-2">
				<v-toolbar color="headings" flat dense class="rounded-t">Райони</v-toolbar>
				<data-loader
					:watch="stationAreasWatch"
					:callback="()=>stationAreasModel.getSelectItems(utility, areaGroup)"
					@ready="stationAreas = null"
				>
					<template #default="{data,loading}">
						<v-progress-linear indeterminate rounded v-if="loading" />
						<div v-else-if="data?.length">
							<base-input type="checkboxgroup" v-model="stationAreas" :options="data" class="mx-2" />
							<v-divider/>
							<v-btn icon @click="checkAllArea(data)"><v-icon>mdi-checkbox-multiple-marked-outline</v-icon></v-btn>
							<v-btn icon @click="uncheckAllArea"><v-icon>mdi-checkbox-multiple-blank-outline</v-icon></v-btn>
						</div>
					</template>
				</data-loader>
			</v-sheet>
		</div>
		<v-divider/>
		<v-toolbar flat dense>
			<v-spacer/>
			<download-btn text color="primary" :rest-service="onExport" :disabled="isDownloadDisabled">
				<v-icon left>mdi-download</v-icon>
				Сваляне
			</download-btn>
		</v-toolbar>
	</div>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import BaseInput from "@/ittijs/Inputs/BaseInput";
import DevicesModel from "../../device_models/Model";
import StationAreasModel from "../../station_areas/Model";
import RadioOtchetiModel from "./RadioOtcheti";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import DownloadBtn from "@/ittijs/components/DownloadBtn.vue";

const devicesModel = ITTIModelProvider.getModel(DevicesModel);
const stationAreasModel = ITTIModelProvider.getModel(StationAreasModel);
const radioOtchetiModel = ITTIModelProvider.getModel(RadioOtchetiModel);

export default {
	components: {DownloadBtn, DataLoader, BaseInput},
	mixins: [SelectedUtilityMixin],
	props: [
		'session', // id
		'utility', // id
	],
	data(){
		return {
			stationAreasModel,
			deviceModels: [],
			deviceModelsList: [],
			areaGroup: null,
			stationAreas: [],
			stationAreasList: [],
		}
	},
	computed: {
		stationAreasWatch(){
			return {
				utility: this.utility,
				areaGroup: this.areaGroup,
			}
		},
		isDownloadDisabled(){
			return (this.deviceModels?.length ?? 0) === 0 || (this.stationAreas?.length ?? 0) === 0;
		},
	},
	mounted() {
		this.getDeviceModelsList();
		stationAreasModel.getSelectItems(this.utility).then(l => {
			this.stationAreasList = l;
		});
	},
	methods: {
		async getDeviceModelsList(){
			this.deviceModelsList = Object.values(await devicesModel.getSelectItems())
				.filter(d=>d.is_radio > 0)
				.map(d=>({
					value: d.id,
					text: `${d.brand} ${d.model} - ${d.device_type_code}`
				}))
			;
		},
		onExport(){
			return radioOtchetiModel.fetch('export', {
				session: this.session,
				models: this.deviceModels,
				areaGroup: this.areaGroup,
				stationAreas: this.stationAreas,
			});
		},
		checkAllModel(){
			this.deviceModels = this.deviceModelsList.map(e => e.value);
		},
		uncheckAllModel(){
			this.deviceModels = [];
		},
		checkAllArea(options){
			this.stationAreas = options.map(e => e.value);
		},
		uncheckAllArea(){
			this.stationAreas = [];
		},
	},
}
</script>