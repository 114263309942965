<template>
	<v-form>
		<v-stepper v-model="step">
			<v-stepper-items>

				<v-stepper-content :step="1" class="pa-0">
					<v-container>
						<v-file-input label="Станции" _accept=".csv" v-model="fileStations" />
						<v-file-input label="Имоти" _accept=".csv" v-model="fileImoti" />
						<v-expansion-panels>
							<v-expansion-panel>
								<v-expansion-panel-header color="headings3">
									<div>
										<v-icon left>mdi-information-outline</v-icon>
										<span class="text-subtitle-1">Файлови формати</span>
									</div>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<br>
									<p>
										Приемат се CSV файлове с посочените по-долу колони, като имената на колоните
										трябва да присъстват на първия ред във всеки от файловете. Неразпознати
										колони се игнорират. Поредността на колоните няма значение.
									</p>
									<p>
										Може да се импортират само част от файловете, напр. само станции или само показания.
									</p>
									<p class="text-h6">Станции</p>
									<ul>
										<li>station_N</li>
										<li>building_N <em>(Пловдив)</em></li>
										<li>address</li>
										<li>mesechen</li>
										<li>VID_SGR</li>
										<li>sgri_2_zones</li>
										<li>sgri_method</li>
										<li>sgri_percent</li>
										<li>sgri_percent_2</li>
										<li>sgri_moshtnost</li>
										<li>domoupravitel_name</li>
										<li>domoupravitel_phone</li>
										<li>domoupravitel_email</li>
										<li>authorized_persons</li>
									</ul>
									<br>
									<p class="text-h6">Имоти</p>
									<ul>
										<li>station_N</li>
										<li>building_N <em>(Пловдив)</em></li>
										<li>imot_N</li>
										<li>imot_N_alt</li>
										<li>imot_type</li>
										<li>address</li>
										<li>location</li>
										<li>volume</li>
										<li>broi_lica</li>
										<li>prognozen_dial_bgv</li>
										<li>prognozen_dial_heat</li>
										<li>prognozen_dial_sgri</li>
										<li>station_zone</li>
										<li>client_N</li>
										<li>client_N_alt</li>
										<li>CONTRACT <em>(София)</em></li>
										<li>client_type</li>
										<li>client_name</li>
										<li>client_phone</li>
										<li>client_phone2</li>
										<li>client_email</li>
									</ul>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-container>
					<v-divider/>
					<v-toolbar flat dense>
						<v-spacer/>
						<v-progress-linear style="width: 200px;" v-if="loading"
							indeterminate
							rounded stream buffer-value="0"
						/>
						<v-btn :disabled="!step1Continue" text color="primary" @click="onProbe">
							<v-icon left>mdi-upload</v-icon>
							Продължи
						</v-btn>
					</v-toolbar>
				</v-stepper-content>

				<v-stepper-content :step="2" class="pa-0">

<!--					<p v-if="!(ifProbeStations || ifProbeImoti)" class="text-center my-5">-->
<!--						Няма променени станции/имоти-->
<!--					</p>-->

					<v-expansion-panels v-if="step>=2" accordion flat>

						<v-expansion-panel>
							<v-divider></v-divider>
							<v-expansion-panel-header>
								Станции
								<v-spacer></v-spacer>
								<div class="text-right">
									<span class="error--text" v-if="probeStationsErrors > 0">&nbsp; грешки: {{ probeStationsErrors }}</span>
									<span class="green--text" v-if="probeStationsInsertTotal">&nbsp; нови: {{ probeStationsInsertSelected }}/{{ probeStationsInsertTotal }}</span>
									<span class="blue--text" v-if="probeStationsUpdateTotal">&nbsp; промяна: {{ probeStationsUpdateSelected }}/{{ probeStationsUpdateTotal }}</span>
									<span class="purple--text" v-if="probeStationsActivateTotal">&nbsp; акт.: {{ probeStationsActivateSelected }}/{{ probeStationsActivateTotal }}</span>
									<span class="orange--text" v-if="probeStationsDeleteTotal">&nbsp; деакт.: {{ probeStationsDeleteTotal }}</span>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<p v-if="!ifProbeStations" class="text-center grey--text">
									{{ fileStations ? 'Няма промени' : 'Няма данни за импорт' }}
								</p>
								<v-list dense subheader v-if="ifProbeStations">

									<template v-if="probeStationsErrors">
										<v-list-item class="error lighten-4">
											<v-list-item-content>
												<v-list-item-title class="text-h6">Грешки</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item v-for="(err, index) in probeResult.stations.errors" :key="index">
											{{err}}
										</v-list-item>
									</template>

									<items-config-diff label="Промяна" class-name="blue"
										v-if="probeStationsUpdateTotal"
										:items="probeResult.stations.update"
										v-model="probeConfig.stations.update"
										:diff="probeResult.stations.diff"
									>
										<template #item="{number}">
											<v-list-item-title>{{ number }}</v-list-item-title>
											<v-list-item-subtitle>{{ getStationInfo(number)['address'] }}</v-list-item-subtitle>
										</template>
									</items-config-diff>

									<items-config label="Активирани" class-name="purple"
										v-if="probeStationsActivateTotal"
										:items="probeResult.stations.data"
										v-model="probeConfig.stations.data"
									>
										<template #item="{number}">
											<v-list-item-title>{{ number }}</v-list-item-title>
											<v-list-item-subtitle>{{ getStationInfo(number)['address'] }}</v-list-item-subtitle>
										</template>
									</items-config>

									<items-config label="Деактивирани" class-name="orange"
										v-if="probeStationsDeleteTotal"
										:items="probeResult.stations.delete"
										v-model="probeConfig.stations.delete"
									>
										<template #item="{number}">
											<v-list-item-title>{{ number }}</v-list-item-title>
											<v-list-item-subtitle>{{ getStationInfo(number)['address'] }}</v-list-item-subtitle>
										</template>
									</items-config>

									<items-config label="Нови" class-name="green"
										v-if="probeStationsInsertTotal"
										:items="probeResult.stations.insert"
										v-model="probeConfig.stations.insert"
									>
										<template #item="{number}">
											<v-list-item-title>{{ number }}</v-list-item-title>
											<v-list-item-subtitle>{{ getStationInfo(number)['address'] }}</v-list-item-subtitle>
										</template>
									</items-config>

								</v-list>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<v-expansion-panel>
							<v-divider></v-divider>
							<v-expansion-panel-header>
								Имоти
								<v-spacer></v-spacer>
								<div class="text-right">
									<span class="error--text" v-if="probeImotiErrors > 0">&nbsp; грешки: {{ probeImotiErrors }}</span>
									<span class="green--text" v-if="probeImotiInsertTotal > 0">&nbsp; нови: {{ probeImotiInsertSelected }}/{{ probeImotiInsertTotal }}</span>
									<span class="blue--text" v-if="probeImotiUpdateTotal > 0">&nbsp; промяна: {{ probeImotiUpdateSelected }}/{{ probeImotiUpdateTotal }}</span>
									<span class="purple--text" v-if="probeImotiActivateTotal">&nbsp; акт.: {{ probeImotiActivateSelected }}/{{ probeImotiActivateTotal }}</span>
									<span class="orange--text" v-if="probeImotiDeleteTotal > 0">&nbsp; липсват: {{ probeImotiDeleteTotal }}</span>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<p v-if="!ifProbeImoti" class="text-center grey--text">
									{{ fileImoti ? 'Няма промени' : 'Няма данни за импорт' }}
								</p>
								<v-list dense subheader v-if="ifProbeImoti">

									<template v-if="probeImotiErrors">
										<v-list-item class="error lighten-4">
											<v-list-item-content>
												<v-list-item-title class="text-h6">Грешки</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item v-for="(err, index) in probeResult.imoti.errors" :key="index">
											{{err}}
										</v-list-item>
									</template>

									<items-config-diff label="Промяна" class-name="blue"
										v-if="probeImotiUpdateTotal"
										:items="probeResult.imoti.update"
										v-model="probeConfig.imoti.update"
										:diff="probeResult.imoti.diff"
									>
										<template #item="{number}">
											<div>{{ number }} - станция {{ stationBuildingN(getImotInfo(number)) }}</div>
											<div>{{ getImotInfo(number)['address'] }}, {{ getImotInfo(number)['client_name'] }}</div>
										</template>
									</items-config-diff>

									<items-config label="Активирани" class-name="purple"
										v-if="probeImotiActivateTotal"
										:items="probeResult.imoti.data"
										v-model="probeConfig.imoti.data"
									>
										<template #item="{number}">
											<v-list-item-title>{{ number }} - станция {{ stationBuildingN(getImotInfo(number)) }}</v-list-item-title>
											<v-list-item-subtitle>{{ getImotInfo(number)['address'] }}, {{ getImotInfo(number)['name'] }}</v-list-item-subtitle>
										</template>
									</items-config>

									<items-config label="Деактивирани" class-name="orange"
										v-if="probeImotiDeleteTotal"
										:items="probeResult.imoti.delete"
										v-model="probeConfig.imoti.delete"
									>
										<template #item="{number}">
											<v-list-item-title>{{ number }} - станция {{ stationBuildingN(getImotInfo(number)) }}</v-list-item-title>
											<v-list-item-subtitle>{{ getImotInfo(number)['address'] }}, {{ getImotInfo(number)['name'] }}</v-list-item-subtitle>
										</template>
									</items-config>

									<items-config label="Нови" class-name="green"
										v-if="probeImotiInsertTotal"
										:items="probeResult.imoti.insert"
										v-model="probeConfig.imoti.insert"
									>
										<template #item="{number}">
											<v-list-item-title>{{ number }} - станция {{ stationBuildingN(getImotInfo(number)) }}</v-list-item-title>
											<v-list-item-subtitle>{{ getImotInfo(number)['address'] }}, {{ getImotInfo(number)['name'] }}</v-list-item-subtitle>
										</template>
									</items-config>

								</v-list>
							</v-expansion-panel-content>
						</v-expansion-panel>

					</v-expansion-panels>
					<v-divider/>
					<v-toolbar flat dense>
						<v-btn :disabled="loading" text @click="step = 1">
							Обратно
						</v-btn>
						<v-spacer/>
						<v-progress-linear style="width: 200px;" v-if="loading"
							indeterminate
							rounded stream buffer-value="0"
						/>
						<v-btn :disabled="!step2Continue" text color="primary" @click="onImport">
							<v-icon left>mdi-upload</v-icon>
							Продължи
						</v-btn>
					</v-toolbar>
				</v-stepper-content>
				<v-stepper-content :step="3" class="pa-0">
					<v-toolbar flat dense>
						<v-btn :disabled="loading" text @click="reset">
							Обратно
						</v-btn>
						<v-spacer/>
					</v-toolbar>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</v-form>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import IModelMixin from "@/ittijs/IModelMixin";
import ItemsConfig from "@/views/sessions/Current/items-config";
import ItemsConfigDiff from "@/views/sessions/Current/items-config-diff";

const countTrue = function(obj){
	let cnt = 0;
	for (const k in obj) {
		if (obj[k]) cnt++;
	}
	return cnt;
}

export default {
	components: {ItemsConfigDiff, ItemsConfig},
	mixins: [SelectedUtilityMixin, IModelMixin],
	props: ['session'],
	data(){
		return {
			fileStations: null,
			fileImoti: null,
			loading: false,
			loadingPercent: null,
			step: 1,
			probeResult: {},
			probeConfig: {
				stations: {
					insert: [],
					update: [],
					delete: [],
					data: [],
				},
				imoti: {
					insert: [],
					update: [],
					delete: [],
					data: [],
				},
			},
			importResult: {},
		}
	},
	computed: {
		files(){
			const result = {};
			this.fileStations && (result['stations'] = this.fileStations);
			this.fileImoti && (result['imoti'] = this.fileImoti);
			return result;
		},

		probeStationsInsertSelected(){
			return this.probeConfig.stations && countTrue(this.probeConfig.stations.insert) || 0;
		},
		probeStationsInsertTotal(){
			return this.probeResult.stations && Object.keys(this.probeResult.stations.insert).length || 0;
		},
		probeStationsUpdateSelected(){
			return this.probeConfig.stations && countTrue(this.probeConfig.stations.update) || 0;
		},
		probeStationsUpdateTotal(){
			return this.probeResult.stations && Object.keys(this.probeResult.stations.update).length || 0;
		},
		probeStationsActivateSelected(){
			return this.probeConfig.stations && countTrue(this.probeConfig.stations.data) || 0;
		},
		probeStationsActivateTotal(){
			return this.probeResult.stations && Object.keys(this.probeResult.stations.data).length || 0;
		},
		probeStationsDeleteTotal(){
			return this.probeResult.stations && Object.keys(this.probeResult.stations.delete).length || 0;
		},
		probeStationsErrors(){
			return this.probeResult.stations && this.probeResult.stations.errors.length || 0;
		},
		ifProbeStations(){
			return !!(this.probeStationsInsertTotal
				|| this.probeStationsUpdateTotal
				|| this.probeStationsActivateTotal
				|| this.probeStationsDeleteTotal
				|| this.probeStationsErrors);
		},

		probeImotiInsertSelected(){
			return this.probeConfig.imoti && countTrue(this.probeConfig.imoti.insert) || 0;
		},
		probeImotiInsertTotal(){
			return this.probeResult.imoti && Object.keys(this.probeResult.imoti.insert).length || 0;
		},
		probeImotiUpdateSelected(){
			return this.probeConfig.imoti && countTrue(this.probeConfig.imoti.update) || 0;
		},
		probeImotiUpdateTotal(){
			return this.probeResult.imoti && Object.keys(this.probeResult.imoti.update).length || 0;
		},
		probeImotiActivateSelected(){
			return this.probeConfig.imoti && countTrue(this.probeConfig.imoti.data) || 0;
		},
		probeImotiActivateTotal(){
			return this.probeResult.imoti && Object.keys(this.probeResult.imoti.data).length || 0;
		},
		probeImotiDeleteTotal(){
			return this.probeResult.imoti && Object.keys(this.probeResult.imoti.delete).length || 0;
		},
		probeImotiErrors(){
			return this.probeResult.imoti && this.probeResult.imoti.errors.length || 0;
		},
		ifProbeImoti(){
			return !!(this.probeImotiInsertTotal
				|| this.probeImotiUpdateTotal
				|| this.probeImotiActivateTotal
				|| this.probeImotiDeleteTotal
				|| this.probeImotiErrors);
		},

		step1Continue(){ // enables continue button on step 1
			if (this.loading) return false;
			return !!(this.fileStations || this.fileImoti);
		},

		step2Continue(){ // enables continue button on step 2
			if (this.loading) return false;
			return this.ifProbeStations || this.ifProbeImoti;
		},

	},
	methods: {
		reset(){
			this.fileStations = null;
			this.fileImoti = null;
			this.probeResult = {};
			this.importResult = {};
			this.step = 1;
		},
		getStationInfo(key){
			try {
				return this.probeResult.stations.stations[key] || {};
			}
			catch (e) {
				return {};
			}
		},
		getImotInfo(imotN){
			try {
				return this.probeResult.imoti.imoti[imotN] || {};
			}
			catch (e) {
				return {};
			}
		},
		onProbe(){
			if (this.fileStations || this.fileImoti) {
				this.loading = true;
				this.loadingPercent = 0;
				const handle = this.imodel.uploadWithProgress(
					'utilityProbe',
					{
						utility: this.selectedUtility,
						session: this.session,
					},
					this.files,
					null,
					(evt)=>{
						if (evt.lengthComputable && evt.total !== 0) {
							this.loadingPercent = evt.loaded * 100 / evt.total;
						}
						else {
							this.loadingPercent = null;
						}
					}
				);
				handle.promise.then(result=>{
					this.onProbeResult(result);
				})
				handle.promise.finally(()=>{
					this.loading = false;
					this.loadingPercent = null;
				});
			}
		},
		onProbeResult(result){
			this.probeResult = result;
			this.probeConfig = {
				stations: {
					insert: [],
					update: [],
					delete: [],
					data: [],
				},
				imoti: {
					insert: [],
					update: [],
					delete: [],
					data: [],
				},
			};
			this.step = 2;
		},
		onImport(){
			if (this.fileStations || this.fileImoti) {
				this.loading = true;
				this.loadingPercent = 0;

				const fdata = new FormData;
				fdata.append('config', JSON.stringify(this.probeConfig));

				// console.log(this.files);
				const handle = this.imodel.uploadWithProgress(
					'utilityImport',
					{
						utility: this.selectedUtility,
						session: this.session,
					},
					this.files,
					fdata,
					(evt)=>{
						if (evt.lengthComputable && evt.total !== 0) {
							this.loadingPercent = evt.loaded * 100 / evt.total;
						}
						else {
							this.loadingPercent = null;
						}
					}
				);
				handle.promise.then(result=>{
					this.onImportResult(result);
				})
				handle.promise.finally(()=>{
					this.loading = false;
					this.loadingPercent = null;
				});
			}
		},
		onImportResult(res){
			this.importResult = res;
			this.step = 3;
		},
		stationBuildingN(imotData){
			let result = imotData['station_N'];
			if (imotData['building_N']!='' && parseInt(imotData['building_N']) > 0) {
				result += '-' + imotData['building_N'];
			}
			return result;
		},
	},
}
</script>