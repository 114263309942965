<template>
	<v-form ref="form">
		<v-container>
			<v-select label="Източник" :items="sourceList" v-model="source" :prepend-icon="icon" :rules="[validators.required('Изберете източник')]">
				<template #item="data">
					<v-list-item v-bind="data.attrs" v-on="data.on" dense>
						<v-list-item-icon class="mr-1">
							<v-icon small>{{data.item.icon}}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							{{data.item.text}}
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-select>
			<v-file-input label="Файл (CSV)" accept=".csv" v-model="file" :rules="fileRules" />
			<br>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header color="headings3">
						<div>
							<v-icon left>mdi-information-outline</v-icon>
							<span class="text-subtitle-1">Файлови формати</span>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<br>
						<p>
							Приемат се CSV файлове с посочените по-долу колони, като имената на колоните
							трябва да присъстват на първия ред във всеки от файловете. Неразпознати
							колони се игнорират. Поредността на колоните няма значение.
						</p>
						<ul>
							<li>device_N</li>
							<li>imot_N</li>
							<li>value</li>
						</ul>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<br>
			<v-alert v-if="result" dismissible type="success" v-model="showResult">
				Общо показания {{result.processed}}, нови {{result.inserted}}, променени {{result.replaced}}
			</v-alert>
			<v-alert dismissible type="error" v-if="resultError">
				{{resultError}}
			</v-alert>
		</v-container>
		<v-divider/>
		<v-toolbar flat dense>
			<v-spacer/>
			<v-progress-linear style="width: 200px;" v-if="loading"
				:indeterminate="loadingPercent===null"
				:value="loadingPercent"
				rounded stream buffer-value="0"
			/>
			<v-btn :disabled="loading" text color="primary" @click="onImport">
				<v-icon left>mdi-upload</v-icon>
				Импорт
			</v-btn>
		</v-toolbar>
	</v-form>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import Validators from "@/ittijs/Validators";
import SelfReportModel from "./SelfReport";

const selfReportModel = new SelfReportModel();

export default {
	mixins: [SelectedUtilityMixin],
	props: [
		'session',
	],
	data(){
		return {
			source: null,
			file: null,
			loading: false,
			loadingPercent: null,
			result: null,
			showResult: true,
			resultError: null,
			validators: Validators,
			fileRules: [
				f => {
					if (f===null) return 'Изберете CSV файл';
					return true;
				},
			],
		}
	},
	computed: {
		icon(){
			const item = this.sourceList.find(e => e.value===this.source);
			return item && item.icon || 'mdi-import';
		},
		sourceList(){
			return [
				{value: '1', text: 'Бележка', icon: 'mdi-note'},
				{value: '2', text: 'Телефон', icon: 'mdi-phone'},
				{value: '3', text: 'Web', icon: 'mdi-web'},
				{value: '4', text: 'Имейл', icon: 'mdi-email'},
			];
		},
	},
	methods: {
		onImport(){
			this.$refs.form.validate();
			if (this.file) {
				this.loading = true;
				this.loadingPercent = 0;
				this.result = null;
				this.showResult = false;
				this.resultError = null;
				const handle = selfReportModel.uploadWithProgress(
					'import',
					{
						utility: this.selectedUtility,
						session: this.session,
						source: this.source,
					},
					{files:this.file},
					null,
					(evt)=>{
						if (evt.lengthComputable && evt.total !== 0) {
							this.loadingPercent = evt.loaded * 100 / evt.total;
						}
						else {
							this.loadingPercent = null;
						}
					}
				);
				handle.promise.then(res=>{
					if (typeof res === 'object') {
						if (res['error']) {
							this.resultError = res['error'];
						}
						else {
							this.result = res;
							this.showResult = true;
							this.source = null;
							this.file = null;
							this.$refs.form.reset();
						}
					}
				});
				handle.promise.catch(err=>{
					this.resultError = err;
				});
				handle.promise.finally(()=>{
					this.loading = false;
					this.loadingPercent = null;
				});
			}
		},
	},
}
</script>

<style scoped>

</style>