<template>
	<v-container fluid>
		<please-select-utility>
			<v-row><v-col>
			<div style="max-width: 400px">
				<session-select
					ref="sessionSelect"
					:utility="selectedUtility"
					v-model="selectedSession"
					:session.sync="sessionRow"
					dense outlined hide-details single-line label="Сесия"
				/>
			</div>
			</v-col></v-row>
			<v-row v-if="selectedSession"><v-col>
			<v-card max-width="1200px">
				<v-tabs v-model="tabs" background-color="headings" slider-color="primary darken-1">
					<v-tab v-if="!isLocked">Импорт от дружество</v-tab>
					<v-tab v-if="!isLocked">Показания на станции</v-tab>
					<v-tab v-if="!isLocked">Радио уреди</v-tab>
					<v-tab v-if="!isLocked">Самоотчети</v-tab>
					<v-tab v-if="!isLocked">Служебни</v-tab>
					<v-tab v-if="!(isLocked || isIzravnitelna)">Консумация</v-tab>
					<v-tab v-if="!isLocked && isIzravnitelna">PDF Сметки</v-tab>
					<v-tab>Експорт за дружество</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabs">
					<v-tab-item v-if="!isLocked"><utility-import :session="selectedSession" /></v-tab-item>
					<v-tab-item v-if="!isLocked"><station-values-import :session="selectedSession" /></v-tab-item>
					<v-tab-item v-if="!isLocked">
						<v-tabs v-model="tabsRadio">
							<v-tab>Експорт за четци</v-tab>
							<v-tab>Импорт показания</v-tab>
							<v-tab>Трансфер</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tabsRadio">
							<v-tab-item><radio-export :session="selectedSession" :utility="selectedUtility" /></v-tab-item>
							<v-tab-item><radio-import :session="selectedSession" @change="resetTransferStats" /></v-tab-item>
							<v-tab-item><radio-transfer ref="radioTransfer" :session="selectedSession" /></v-tab-item>
						</v-tabs-items>
					</v-tab-item>
					<v-tab-item v-if="!isLocked">
						<v-tabs v-model="tabsSelfReport">
							<v-tab>Импорт</v-tab>
							<v-tab>Трансфер</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tabsSelfReport">
							<v-tab-item><self-report :session="selectedSession" /></v-tab-item>
							<v-tab-item>
								<download-btn
									class="ma-4"
									color=""
									:rest-service="downloadAllSelfReport"
								>
									<v-icon left>mdi-microsoft-excel</v-icon>
									Свали всички самоотчети за сесията
								</download-btn>
								<v-tabs v-model="tabsSelfReportSummer" background-color="grey lighten-3">
									<v-tab>Зима</v-tab>
									<v-tab>Лято</v-tab>
								</v-tabs>
								<v-tabs-items v-model="tabsSelfReportSummer">
									<v-tab-item><self-report-transfer :session="selectedSession" /></v-tab-item>
									<v-tab-item><self-report-transfer :session="selectedSession" summer /></v-tab-item>
								</v-tabs-items>
							</v-tab-item>
						</v-tabs-items>
					</v-tab-item>
					<v-tab-item v-if="!isLocked">
						<slujebni-update :session="selectedSession" />
					</v-tab-item>
					<v-tab-item v-if="!(isLocked || isIzravnitelna)">
						<session-consumption
							:session="selectedSession"
						/>
					</v-tab-item>
					<v-tab-item v-if="!isLocked && isIzravnitelna">
						<smetki-pdf
							:session="selectedSession"
						/>
					</v-tab-item>
					<v-tab-item>
						<utility-export
							:session="selectedSession"
							@reload="reloadSessions"
						/>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
			</v-col></v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import Model from "./Model";
import SessionSelect from "./SessionSelect";
import UtilityImport from "@/views/sessions/Current/utility-import";
import RadioExport from "@/views/sessions/Current/radio-export";
import RadioImport from "@/views/sessions/Current/radio-import";
import UtilityExport from "@/views/sessions/Current/utility-export";
import RadioTransfer from "@/views/sessions/Current/radio-transfer";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import SelfReport from "@/views/sessions/Current/self-report";
import SelfReportTransfer from "@/views/sessions/Current/self-report-transfer";
import StationValuesImport
	from "@/views/sessions/Current/station-values-import";
import SessionConsumption from "@/views/sessions/Current/session-consumption";
import SlujebniUpdate from "@/views/sessions/Current/slujebni-update";
import SelfReportModel from "@/views/sessions/Current/SelfReport";
import DownloadBtn from "@/ittijs/components/DownloadBtn";
import SmetkiPdf from "@/views/sessions/Current/smetki-pdf.vue";

const imodel = new Model();

const selfReportModel = new SelfReportModel();

export default {
	components: {
		SmetkiPdf,
		SlujebniUpdate,
		SessionConsumption,
		StationValuesImport,
		SelfReportTransfer,
		SelfReport,
		RadioTransfer,
		UtilityExport,
		RadioImport,
		RadioExport,
		UtilityImport,
		SessionSelect,
		PleaseSelectUtility,
		DownloadBtn,
	},

	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility:'selectedUtility',
			session:'selectedSession',
		}),
	],

	props: [
		'utility',
		'session',
	],

	data(){
		return {
			tabs: 0,
			tabsRadio: 0,
			tabsSelfReport: 0,
			tabsSelfReportSummer: 0,
			selectedSession: null,
			sessionRow: {},
		}
	},

	watch: {
		utility(){
			this.selectedSession = null;
		},
	},

	computed: {
		isLocked(){
			return (this.sessionRow && this.sessionRow.locked==='1') || false;
		},
		isIzravnitelna(){
			return (this.sessionRow && this.sessionRow.izr_sess != '0') || false;
		},
	},

	provide: function () {
		return {
			imodel,
		};
	},

	methods: {
		resetTransferStats(){
			this.$refs.radioTransfer && this.$refs.radioTransfer.reset();
		},
		reloadSessions(){
			this.$refs.sessionSelect.loadItems();
		},
		async downloadAllSelfReport(){
			return await selfReportModel.downloadAll(this.selectedUtility, this.session, this.summer);
		},
	},

}
</script>