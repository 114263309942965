<template>
	<v-container>
		<v-row>
			<v-col>
				<v-alert type="info" dense>
					Ако тази операция е правена отделно за някоя станция, стойностите за
					нея не са отразени тук и ще бъдат загубени!
				</v-alert>

				<form-slujebni
					:session="session"
					:value.sync="data"
					ref="form"
					:readonly="loading"
				/>

				<v-divider />

				<div class="d-flex pt-2">
					<v-spacer/>
					<btn-progress-confirm
						button-label="Потвърди"
						:callback="()=>update()"
						@ready="ready"
						:button-props="{
							color: 'primary',
							small: false,
						}"
					/>
				</div>

			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import FormSlujebni from "@/ittijs/components/FormSlujebni";
import IModelMixin from "@/ittijs/IModelMixin";
import BtnProgressConfirm from "@/ittijs/components/BtnProgressConfirm";
export default {
	components: {BtnProgressConfirm, FormSlujebni},
	mixins: [IModelMixin, SelectedUtilityMixin],
	props: ['session'],
	data(){
		return {
			data: null,
			loading: false,
		}
	},
	watch: {
		selectedUtility: {
			immediate: true,
			handler() {
				this.load();
			},
		},
		session: {
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		async load(){
			if (!this.selectedUtility) return;
			if (!this.session) return;
			try {
				this.loading = true;
				this.data = await this.imodel.getConfigSlujeben(this.selectedUtility, this.session);
			}
			finally {
				this.loading = false;
			}
		},
		async update(){
			this.loading = true;
			return await this.imodel.recalcSlujebenAll(this.selectedUtility, this.session, this.data);
		},
		ready(){
			this.loading = false;
		},
	},
}
</script>