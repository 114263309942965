<template>
	<div>
		<v-container class="text-body-1">

			<div v-if="lockStatus">
				<v-alert dense border="left" type="success">
					Сесията е заключена и готова за експорт към дружеството
					<template #append>
						<confirm-dialog @confirm="unlock">
							<template #activator="{attrs,on}">
								<div class="text-right">
									<v-btn v-bind="attrs" v-on="on" color="success">
										<v-icon left>mdi-lock-open-variant</v-icon>
										Отключи сесията
									</v-btn>
								</div>
							</template>
						</confirm-dialog>
					</template>
				</v-alert>

				<v-file-input label="Входни файлове" multiple v-model="filesExport" />

			</div>

			<div v-else>
				<v-alert dense border="left" type="warning" icon="mdi-alert">
					Сесията все още е отключена!<br>
					Експорт може да бъде направен само при заключена сесия!
					<template #append>
						<confirm-dialog @confirm="lock">
							<template #activator="{attrs,on}">
								<div class="text-right">
									<v-btn v-bind="attrs" v-on="on" color="error">
										<v-icon left>mdi-lock-check</v-icon>
										Заключи сесията
									</v-btn>
								</div>
							</template>
						</confirm-dialog>
					</template>
				</v-alert>
			</div>

		</v-container>

		<v-divider v-if="lockStatus" />
		<v-toolbar v-if="lockStatus" flat dense>
			<v-spacer/>
			<download-btn-with-progress
				:rest-service="()=>imodel.utilityExport(selectedUtility, session, arrayToFileList(filesExport))"
				:button-props="{
					text: true,
					color: 'primary',
					small: false,
				}"
				@error="throwMe"
			>
				<template #button-content>
					<v-icon left>mdi-export</v-icon>
					Експорт
				</template>
			</download-btn-with-progress>
		</v-toolbar>

	</div>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import IModelMixin from "@/ittijs/IModelMixin";
import ConfirmDialog from "@/ittijs/ConfirmDialog";
import {arrayToFileList} from "@/ittijs/utils";
import DownloadBtnWithProgress
	from "@/ittijs/components/DownloadBtnWithProgress";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";

export default {
	components: {DownloadBtnWithProgress, ConfirmDialog},
	mixins: [SelectedUtilityMixin, IModelMixin, ThrowMeMixin],
	props: ['session'],
	data(){
		return {
			lockStatus: false,
			loading: false,
			filesExport: null,
		}
	},
	watch: {
		session: {
			handler(){
				this.getStatus();
			},
			immediate: true,
		},
	},
	methods: {
		arrayToFileList,
		async getStatus(){
			this.loading = true;
			this.lockStatus = await this.imodel.getLockStatus(this.selectedUtility, this.session);
			this.loading = false;
		},
		async lock(){
			this.loading = true;
			const res = await this.imodel.lockSession(this.selectedUtility, this.session);
			if (res===true) {
				this.lockStatus = true;
			}
			this.loading = false;
			this.$emit('reload');
		},
		async unlock(){
			this.loading = true;
			const res = await this.imodel.unlockSession(this.selectedUtility, this.session);
			if (res===false) {
				this.lockStatus = false;
			}
			this.loading = false;
			this.$emit('reload');
		},
	},
}
</script>