<template>
	<v-form>
		<v-container>
			<v-file-input label="Показания (станции) - CSV" _accept=".csv" v-model="fileValues" />
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header color="headings3">
						<div>
							<v-icon left>mdi-information-outline</v-icon>
							<span class="text-subtitle-1">Файлов формат</span>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<br>
						<p>
							Приемат се CSV файлове с посочените по-долу колони, като имената на колоните
							трябва да присъстват на първия ред във файла. Неразпознати
							колони се игнорират. Поредността на колоните няма значение.
						</p>
						<p class="text-h6">Показания</p>
						<ul>
							<li>station_N</li>
							<li>building_N</li>
							<li>toplomer_obsht_value</li>
							<li>vodomer_hot_value</li>
							<li>toplomer_heat_value</li>
							<li>toplomer_heat_2_value</li>
							<li>work_days_heat</li>
							<li>work_days_hw</li>
							<li>t_avg_session</li>
							<li>day_degree</li>
							<li>work_days_wo_hot_vodomer</li>
							<li>temperaturen_coeficient</li>
						</ul>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<br>
			<p>След импорт ще бъде върнат файл с репорт за извършените операции</p>
		</v-container>
		<v-divider/>
		<v-toolbar flat dense>
			<v-spacer/>
			<v-progress-linear style="width: 200px;" v-if="loading"
				:indeterminate="loadingPercent===null"
				:value="loadingPercent"
				rounded stream buffer-value="0"
			/>
			<v-btn text color="primary" @click="onImport">
				<v-icon left>mdi-upload</v-icon>
				Импорт
			</v-btn>
		</v-toolbar>
	</v-form>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import IModelMixin from "@/ittijs/IModelMixin";

export default {
	mixins: [SelectedUtilityMixin, IModelMixin],
	props: ['session'],
	data(){
		return {
			fileValues: null,
			loading: false,
			loadingPercent: null,
		}
	},
	computed: {
		files(){
			const result = {};
			this.fileValues && (result['values'] = this.fileValues);
			return result;
		},
	},
	methods: {
		reset(){
			this.fileValues = null;
		},
		onImport(){
			if (this.fileValues) {
				this.loading = true;
				this.loadingPercent = 0;

				const handle = this.imodel.uploadWithProgress(
					'utilityImportStationValues',
					{
						utility: this.selectedUtility,
						session: this.session,
					},
					this.files,
					null,
					(evt)=>{
						if (evt.lengthComputable && evt.total !== 0) {
							this.loadingPercent = evt.loaded * 100 / evt.total;
						}
						else {
							this.loadingPercent = null;
						}
					}
				);
				handle.promise.then(result=>{
					if (typeof result === 'string') {
						window.location.href = result;
					}
				})
				handle.promise.finally(()=>{
					this.loading = false;
					this.loadingPercent = null;
				});
			}
		},
	},
}
</script>