<template>
	<v-form>
		<div class="ma-2">
			Формат:
			<v-radio-group hide-details v-model="format">
				<v-radio value="qundis" label="Qundis" />
				<v-radio value="apator" label="Apator" />
			</v-radio-group>
			<v-file-input label="Файл" :accept="acceptFiles" multiple v-model="files" />
			<v-simple-table v-if="result" style="width: 400px">
				<caption>Резултати</caption>
				<tbody>
				<tr>
					<th>Брой записи във файла</th>
					<td align="right">{{ result }}</td>
				</tr>
				</tbody>
			</v-simple-table>
		</div>
		<v-divider/>
		<v-toolbar flat dense>
			<confirm-dialog @confirm="onDelete">
				<template #activator="{on}">
					<v-btn
						v-on="on"
						text color="error"
						:disabled="loading"
					>
						<v-icon left>mdi-delete</v-icon>
						Изчисти
					</v-btn>
				</template>
				<p>
					Ще бъдат изтрити всички импортирани радио отчети, които все
					още не са трансферирани към уред.
				</p>
				<p>
					Това няма да се отрази на показания на уреди.
				</p>
			</confirm-dialog>
			<v-spacer/>
			<v-progress-linear style="width: 200px;" v-if="loading"
				indeterminate
				rounded stream buffer-value="0"
			/>
			<v-btn :disabled="loading" text color="primary" @click="onImport">
				<v-icon left>mdi-upload</v-icon>
				Импорт
			</v-btn>
		</v-toolbar>
	</v-form>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RadioOtchetiModel from "./RadioOtcheti";
import ConfirmDialog from "@/ittijs/ConfirmDialog";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";

const radioOtchetiModel = new RadioOtchetiModel();

export default {
	components: {ConfirmDialog},
	mixins: [SelectedUtilityMixin, ThrowMeMixin],
	props: ['session'],
	data(){
		return {
			format: 'qundis',
			files: null,
			loading: false,
			loadingPercent: null,
			result: null,
		}
	},
	computed: {
		acceptFiles(){
			switch (this.format) {
				case 'qundis': return '.xml';
				case 'apator': return '.txt';
			}
			return '';
		},
	},
	methods: {
		onImport(){
			if (this.files) {
				this.loading = true;
				this.loadingPercent = 0;
				this.result = null;
				const handle = radioOtchetiModel.uploadWithProgress(
					'import',
					{
						utility: this.selectedUtility,
						session: this.session,
						format: this.format,
					},
					{files:this.files},
					null,
					(evt)=>{
						if (evt.lengthComputable && evt.total !== 0) {
							this.loadingPercent = evt.loaded * 100 / evt.total;
						}
						else {
							this.loadingPercent = null;
						}
					}
				);
				handle.promise.then(res=>{
					if (typeof res === 'object') this.result = res;
				});
				handle.promise.catch(function(err){
					this.throwMe(err.response);
				}.bind(this));
				handle.promise.finally(()=>{
					this.loading = false;
					this.loadingPercent = null;
					this.files = null;
					this.$emit('change');
				});
			}
		},
		async onDelete(){
			if (!this.loading) {
				this.loading = true;
				await radioOtchetiModel.deleteReadings(this.selectedUtility, this.session);
				this.loading = false;
				this.$emit('change');
			}
		},
	},
}
</script>

<style scoped>

</style>