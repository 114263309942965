import { render, staticRenderFns } from "./session-consumption.vue?vue&type=template&id=39bdd4a0"
import script from "./session-consumption.vue?vue&type=script&lang=js"
export * from "./session-consumption.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports